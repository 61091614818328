import { useLocalStorage } from '../../providers/LocalStorageProvider'
import CoreSidebarMinifyButton from './CoreSidebar/CoreSidebarMinifyButton'
import CoreSidebarNavigationItems from './CoreSidebar/CoreSidebarNavigationItems'
import CoreSidebarProfileButton from './CoreSidebar/CoreSidebarProfileButton/CoreSidebarProfileButton'

const CoreSidebar: React.FC = () => {
  const [getLocalStorage] = useLocalStorage()

  const minified = getLocalStorage('sidebarMinified', 'true')

  return (
    <div
      className={`z-40 flex flex-col inset-y-0 fixed bottom-0 h-screen border-r ${minified ? 'w-20' : 'w-52'}`}
      id="sidebar"
    >
      <div className="flex flex-col justify-between h-full min-h-0 bg-black">
        <CoreSidebarNavigationItems minified={minified} />
        <div className="flex flex-col w-full">
          <CoreSidebarProfileButton minified={minified} />
          <CoreSidebarMinifyButton minified={minified} />
        </div>
      </div>
    </div>
  )
}

export default CoreSidebar
