import { IntakeSectionContentProps } from '../IntakeSection'
import useUpdateFormField from '../../../../IntakeFormResponse/hooks/useUpdateFormField'
import useIntakeFormStore from '../../../../IntakeFormResponse/stores/useIntakeFormStore'
import { useIntakeFlow } from '../../../providers/IntakeFlowProvider'
import IntakeFormField from '../IntakeFormField'
import IntakeFormFieldCoPilot from '../IntakeFormFieldCoPilot'
import cn from '../../../../../../lib/util'

const IntakeBasicSection = ({ section }: IntakeSectionContentProps) => {
  const isDraftIntake = useIntakeFormStore((state) => state.intake?.status === 'draft')
  const isInLastSection = useIntakeFormStore(
    (state) => state.intake && state.activeSectionIndex === state.intake.sections.length - 1
  )

  const { updateField, editModeOn } = useIntakeFlow()
  const updateStoreField = useUpdateFormField()

  // if we're using the intake store (in form submission),
  // use the update function from the intake store instead of the intake provider
  let updateFieldFn = updateField
  if (isDraftIntake) {
    updateFieldFn = updateStoreField
  }

  const lastVisibleField = section.fields
    .filter((field) => field.visible && field.input.settings?.misc?.hidden === false)
    .slice(-1)[0]

  const lastVisibleFieldIsSelect =
    lastVisibleField && ['SELECT', 'MULTI_SELECT', 'SEARCH_SELECT'].includes(lastVisibleField?.input.type)
  const shouldAddPadding = isDraftIntake && !isInLastSection && lastVisibleFieldIsSelect

  return (
    <div className={cn('grid grid-cols-1 gap-8', shouldAddPadding && 'pb-52')}>
      {section.fields.map((field) =>
        field.input.settings?.coPilot ? (
          <IntakeFormFieldCoPilot
            key={`intake-form-field-copilot-${field.id}`}
            field={field}
            updateField={updateFieldFn}
            editModeOn={editModeOn}
          />
        ) : (
          <IntakeFormField
            key={`intake-form-field-${field.id}`}
            field={field}
            updateField={updateFieldFn}
            editModeOn={editModeOn}
          />
        )
      )}
    </div>
  )
}

export default IntakeBasicSection
