import { apiGet, apiPost, apiPut, apiUpload } from '../../../core/utils/api/generic'
import {
  ExternalDataColumn,
  ExternalDataListEntity,
  ExternalDataRelationship,
} from '../../../types/ExternalDataManager/ExternalDataManagerTypes'
import apiClient from '../../../core/utils/apiClient'

// external-data-manager/entities
export const apiListExternalDataEntities = async () =>
  apiGet<ExternalDataListEntity[]>('external-data-manager/entities')

export const apiUpdateExternalDataEntity = ({ uuid, ...data }: Pick<ExternalDataListEntity, 'uuid' | 'name'>) =>
  apiPut(`external-data-manager/entity/${uuid}`, data)

// external-data-manager/entity/{{fileUuid}}/columns
export const apiListExternalDataEntityColumns = async (fileUuid: string) =>
  apiGet<ExternalDataColumn[]>(`external-data-manager/entity/${fileUuid}/columns`)

export type ExternalDataRow = Record<string, string | number> & { id: number }

type ExternalDataResponse = {
  totalCount: number
  results: ExternalDataRow[] | ExternalDataRow[][]
}
// external-data-manager/query/{{fileUuid}}{{query_string}}, where query_string looks like "?{column}=eq.Some Value"
export const apiGetExternalDataEntityData = async (fileUuid: string, queryString: string = '') =>
  apiGet<ExternalDataResponse>(`external-data-manager/query/${fileUuid}${queryString || ''}`)

export const apiGetExternalDataEntityRelationships = (fileUuid: string) =>
  apiGet<ExternalDataColumn[]>(`external-data-manager/mapping/${fileUuid}/columns`)

export const apiUploadExternalData = async (name: string, file: File) => {
  const formData = new FormData()
  formData.append('name', name)
  formData.append('file', file)

  return apiUpload('external-data-manager/upload', formData)
}

export const apiPatchExternalDataEntityColumns = async ({
  uuid,
  columns,
}: {
  uuid: string
  columns: ExternalDataColumn[]
}) => {
  const body = {
    columns,
  }
  const result = await apiClient.patch(`external-data-manager/entity/${uuid}/columns`, body)
  return result || []
}

export const apiSaveExternalDataRelationship = ({
  source_uuid,
  source_column,
  target_column,
  target_uuid,
}: ExternalDataRelationship) =>
  apiPost(`external-data-manager/mapping/${source_uuid}/column/${source_column}`, {
    foreign_uuid: target_uuid || null,
    foreign_column_name: target_column || null,
  })
