import { UserIcon } from '@heroicons/react/24/outline'
import { NavLink } from 'react-router-dom'
import { UserProfileType } from '../../../../../types/shared'
import activeMark from '../../../../assets/active-item-mark.svg'
import generateUserName from '../../../../../shared/utils/generateUserName'

interface ICoreSidebarProfile {
  href: string
  activeUrl: string
  minified?: boolean
  data: UserProfileType
}

const CoreSidebarProfile: React.FC<ICoreSidebarProfile> = ({ href, activeUrl, minified, data: userProfile }) => {
  const isActive = activeUrl === href
  const displayName = generateUserName(userProfile)

  return (
    <div className="flex items-center w-full">
      {isActive && <img src={activeMark} alt="" className="absolute l-0" />}
      <NavLink
        to={href}
        className={`
          ${isActive ? 'text-white' : 'text-white opacity-70'}
          ${minified ? 'justify-center' : ''}
          w-full p-2 hover:text-white flex flex-row rounded-md items-center`}
      >
        <div className="max-h-full">
          <UserIcon className="h-5" />
        </div>
        {!minified && <div className="w-full ml-3 pr-2 items-center text-sm truncate">{displayName}</div>}
      </NavLink>
    </div>
  )
}

export default CoreSidebarProfile
