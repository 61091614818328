import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import React, { useState } from 'react'
import { useHistory } from 'react-router'
import _ from 'lodash'
import dayjs from 'dayjs'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../../../../core/components/Table/Table'
import settingsQueryKeys from '../Profile/constants/settingsQueryKeys'
import { apiGetUser } from '../Profile/services/SettingsService'
import useGetPaginatedUsers from './hooks/useGetPaginatedUsers'
import usePermissions from '../../../../core/hooks/usePermissions'
import { USER_DELETE, USER_EDIT } from '../../../../shared/constants/permissions'
import { Avatar, AvatarFallback } from '../../../../shared/components/atoms/Avatar/Avatar'
import stringToHslColor from '../../../../shared/utils/stringToHslColor'
import getUserInitials from '../../../../shared/utils/getUserInitials'
import generateUserName from '../../../../shared/utils/generateUserName'
import UserTypeSelect from './UsersForm/UserTypeSelect'
import { dateFormat } from '../../../../shared/constants/dateFormat'
import Menu from '../../../../shared/components/organisms/menus/Menu'
import ConfirmationModal from '../../../../shared/components/organisms/modals/ConfirmationModal'
import { apiPostResetUserPassword } from '../../services/CompanyService'
import { useUtils } from '../../../../core/providers/UtilsProvider'
import { Pagination } from '../../../../shared/components/atoms/SharedTable/Pagination'
import { CompanyUserType } from '../../../../types/shared/RequisitionDataTypes'
import useDeleteCompanyUser from '../../hooks/useDeleteCompanyUser'

const UsersTable = () => {
  const { t } = useTranslation()
  const [page, setPage] = useState(1)
  const { data: paginatedResponse, isLoading } = useGetPaginatedUsers(page)
  const { data: currentUser } = useQuery([settingsQueryKeys.USER_PROFILE], apiGetUser)
  const { mutate: deleteUserMutation } = useDeleteCompanyUser()

  const { modal } = useUtils()
  const {
    data: users,
    meta: { per_page, total },
  } = paginatedResponse || { meta: { total: 1, per_page: 1 } }

  const pageCount = Math.ceil(total / per_page)
  const permissions = usePermissions([{ slug: USER_EDIT }, { slug: USER_DELETE }])
  const history = useHistory()

  const resetPassword = (user: CompanyUserType) => {
    modal.set({
      isOpen: true,
      title: t('generic.confirm'),
      content: (
        <ConfirmationModal
          onConfirm={() => {
            user && apiPostResetUserPassword({ email: user.email })
            modal.set({ isOpen: false })
          }}
        >
          {t('account.users_confirm_reset_password')}
        </ConfirmationModal>
      ),
    })
  }

  const deleteUser = (user: CompanyUserType) => {
    modal.set({
      isOpen: true,
      title: t('generic.delete_are_you_sure', 'Are you absolutely sure?'),
      content: (
        <ConfirmationModal
          confirmString={t('generic.delete', 'Delete')}
          onConfirm={() => {
            deleteUserMutation(user.id)
            modal.set({ isOpen: false })
          }}
        >
          {t(
            'account.users_confirm_delete',
            'You are about to permanently delete this user. Please be aware that this action cannot be undone and will permanently erase the user'
          )}
        </ConfirmationModal>
      ),
    })
  }

  const getUserMenuItems = (user: CompanyUserType) => {
    const userMenuItems = []

    const userCanEdit = permissions.get(USER_EDIT).allowed
    if (userCanEdit) {
      userMenuItems.push({
        name: t('generic.edit'),
        onClick: () => resetPassword(user),
      })
    }

    const userCanDelete = permissions.get(USER_DELETE).allowed && user.id !== currentUser?.id
    if (userCanDelete) {
      userMenuItems.push({
        name: t('generic.delete'),
        onClick: () => deleteUser(user),
      })
    }

    return userMenuItems
  }

  return (
    <>
      <Table className="bg-white shadow-rock-gray-light">
        <TableHeader>
          <TableRow>
            <TableHead className="w-96 font-bold first-letter:uppercase">{t('generic.name', 'Name')}</TableHead>
            <TableHead className="w-48 font-bold first-letter:uppercase">{t('generic.email', 'Email')}</TableHead>
            <TableHead className="w-48 font-bold first-letter:uppercase">{t('generic.user_role', 'Role')}</TableHead>
            <TableHead className="w-48 font-bold first-letter:uppercase">
              {t('account.user_member_since', 'Sign up date')}
            </TableHead>
            <TableHead className="w-48 font-bold first-letter:uppercase text-right">
              {t('generic.actions', 'Actions')}
            </TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {!isLoading &&
            users &&
            users?.map((user) => (
              <TableRow
                key={`users-${user.id}`}
                className="h-16 hover:bg-gray-200/25 text-black-light hover:cursor-pointer"
                onClick={() => history.push(`/users/${user.id}`)}
              >
                <TableCell className="first-letter:uppercase">
                  <div className="flex space-x-2 items-center">
                    <Avatar>
                      <AvatarFallback
                        style={{ backgroundColor: stringToHslColor({ name: getUserInitials(user) }) }}
                        className="font-semibold"
                      >
                        {getUserInitials(user)}
                      </AvatarFallback>
                    </Avatar>
                    <p className="text-sm text-ellipsis overflow-hidden line-clamp-2">{generateUserName(user)}</p>
                  </div>
                </TableCell>
                <TableCell className="first-letter:uppercase">
                  <p className="text-sm text-ellipsis overflow-hidden line-clamp-2">{user.email}</p>
                </TableCell>
                <TableCell className="first-letter:uppercase w-96">
                  {permissions.get(USER_EDIT).allowed ? (
                    <UserTypeSelect userId={user.id} userRole={user.role} currentUser={currentUser} />
                  ) : (
                    <p>{_.capitalize(user.role)}</p>
                  )}
                </TableCell>
                <TableCell>{dayjs(user.created_at).format(dateFormat)}</TableCell>
                <TableCell>
                  <Menu menuItems={getUserMenuItems(user)} />
                </TableCell>
              </TableRow>
            ))}
          {!isLoading && !users && <div className="flex w-full text-gray-900">{t('account.no_users')}</div>}
        </TableBody>
      </Table>
      {pageCount > 1 && (
        <div className="mt-4 w-full xl:w-3/4 self-center flex justify-center">
          <Pagination
            onPageChange={({ selected }) => setPage(selected + 1)}
            forcePage={page - 1}
            pageCount={pageCount}
          />
        </div>
      )}
    </>
  )
}
export default UsersTable
