// Function uses an input uuid to look up an input, and then gets the filter_settings field
// from the input. The filter_settings field can be empty, or it can be an object of type FilterSettingsType.
// If it isn't empty, use filter_settings to get the data source, the input_value_column, and the visible_columns.
// use the data_source nad input value column to look up the data source using the ExternalDataManager API.

import _ from 'lodash'
import useGetInputById from '../../modules/Forms/hooks/useGetInputById'
import useGetExternalDataEntityData from '../../modules/ExternalDataManager/hooks/useGetExternalDataEntityData'
import { InputSearchSelectItemType } from '../../types/shared/InputSearchSelectTypes'
import useIntakeFormStore from '../../modules/Intake/IntakeFormResponse/stores/useIntakeFormStore'
import { IntakeType } from '../../types/Intake'
import { useIntakeFlow } from '../../modules/Intake/IntakeOverview/providers/IntakeFlowProvider'

const useGetSearchSelectOptionsFromFile = (
  questionUuid: string | null | undefined,
  inputUuid: string | null | undefined
) => {
  const { data: input } = useGetInputById(inputUuid)

  const filterSettings = input?.filter_settings
  const { data_source, conditions, input_value_column, visible_columns } = filterSettings || {}

  let queryString = ''

  const { intake } = useIntakeFlow()

  const intakeStore = useIntakeFormStore((state) => state.intake)
  const offeringsStore = useIntakeFormStore((state) => state.offerings)
  const intakeToUse: IntakeType | null = intake || intakeStore

  const offering = offeringsStore?.find((offering) => offering.fields.find((field) => field.uuid === questionUuid))

  if (conditions && conditions?.length > 0) {
    let parentVal
    if (intakeToUse) {
      // If there is an intake and an offering available then check the offering fields
      if (offering) {
        parentVal = offering?.fields.find((field) => field.input.uuid === conditions[0].parent_input_uuid)?.value?.value
      } else {
        // Else find the field in the intake and use that fields value
        parentVal = intakeToUse.sections
          .find((section) => section.fields.find((field) => field.input.uuid === conditions[0].parent_input_uuid))
          ?.fields.find((field) => field.input.uuid === conditions[0].parent_input_uuid)?.value?.value
      }
    }

    if (parentVal) {
      queryString = `?${conditions[0].parent_column}=eq.${parentVal}`
    }
  }

  if (visible_columns) {
    // if query string is not empty, use & instead of ?
    if (queryString) {
      queryString += '&'
    } else {
      queryString += '?'
    }
    queryString += `select=id,${visible_columns}`
  }

  const { data: dataObjects, isLoading } = useGetExternalDataEntityData(data_source, queryString)

  const visibleColumns = visible_columns?.split(',')

  // Map the data objects to options and return it
  const options: InputSearchSelectItemType[] | undefined = dataObjects?.results
    ?.flat()
    .map((dataObject: { [x: string]: any; id: any }) => ({
      id: dataObject?.id || dataObject?._id,
      label: input_value_column ? dataObject[input_value_column] : '',
      dataFields: visibleColumns
        ? _.pick(dataObject, visibleColumns) // Include the data fields that are visible, for rendering in the search select
        : dataObject,
      isExternalDataOption: true,
    }))

  return { options, visibleColumns, isLoading }
}

export default useGetSearchSelectOptionsFromFile
