import { useMutation, useQueryClient } from 'react-query'
import { apiDeleteUser } from '../services/CompanyService'
import companyQueryStrings from '../constants/companyQueryStrings'
import { toastr } from '../../../shared/components/organisms/toastr'

const useDeleteCompanyUser = () => {
  const cache = useQueryClient()

  return useMutation((user_id: number) => apiDeleteUser({ id: user_id }), {
    onSuccess: () => {
      cache.invalidateQueries([companyQueryStrings.company_users])
      toastr.success('success.delete_user', 'companyUserDelete')
    },
  })
}

export default useDeleteCompanyUser
