import { useTranslation } from 'react-i18next'
import { useMemo, useState } from 'react'
import CoreLoadingIcon from '../../../../core/components/CoreLoadingIcon'
import TableContainer from '../../../../shared/components/atoms/SharedTable/TableContainer'
import ExternalDataTableBody from './ExternalDataTableBody'
import ExternalDataTableHeader from './ExternalDataTableHeader'
import { DynamicRowData } from './ExternalDataTableRow'
import useGetExternalDataEntityData from '../../hooks/useGetExternalDataEntityData'
import useCurrentExternalDataSourceUuid from '../../hooks/useCurrentExternalDataSourceUuid'
import { Pagination } from '../../../../shared/components/atoms/SharedTable/Pagination'

export const ExternalDataTable = () => {
  const [page, setPage] = useState(1)
  const per_page = 20
  const query = `?limit=${per_page}&offset=${(page - 1) * per_page}`

  const uuid = useCurrentExternalDataSourceUuid()
  const { data, isLoading } = useGetExternalDataEntityData(uuid, query)
  const { t } = useTranslation()

  const { results, totalCount } = data || {}

  const formattedData = useMemo(() => {
    if (!results) return []
    return results?.flat()
  }, [results])

  const dataCount = useMemo(() => {
    if (!formattedData) return 0
    return formattedData.length
  }, [formattedData])

  const pageCount = Math.ceil((totalCount || dataCount) / per_page)

  if (isLoading)
    return (
      <div className="m-4">
        <CoreLoadingIcon />
      </div>
    )

  const columns: string[] = Object.keys(formattedData[0] || {})
  const rows: DynamicRowData[] = formattedData

  return (
    <div>
      <TableContainer
        emptyState={
          !rows?.length && (
            <div className="flex flex-col items-center">
              <div className="text-rock-gray">{t('external_data.missing_data', 'No data provided')}</div>
            </div>
          )
        }
        footer={
          <Pagination
            onPageChange={({ selected }) => setPage(selected + 1)}
            forcePage={page - 1}
            pageCount={pageCount}
          />
        }
      >
        <ExternalDataTableHeader columns={columns} />
        <ExternalDataTableBody rows={rows} />
      </TableContainer>
    </div>
  )
}

export default ExternalDataTable
