import { useQuery } from 'react-query'
import { apiGetExternalDataEntityData } from '../services/ExternalDataServices'

const useGetExternalDataEntityData = (fileUuid: string | undefined, queryString?: string) => {
  const { data, isLoading, isFetching, error } = useQuery(['entity-column-data', fileUuid, queryString], () => {
    if (!fileUuid) return Promise.resolve({ totalCount: 0, results: [] })

    return apiGetExternalDataEntityData(fileUuid, queryString)
  })

  return {
    data,
    isLoading: isLoading || isFetching,
    error,
  }
}

export default useGetExternalDataEntityData
